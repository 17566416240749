import React, { useEffect, useState } from "react";

import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import Intro from "../components/sales-estimator/Intro";
import OgImg from "../assets/images/raster/pages/sales-estimator/common/seo_sales-estimator.png";
import "../components/sales-estimator/index.scss";
// import ProPanelContainer from "../containers/sales-estimator/ProPanelContainer";
import EstimatorContainer from "../containers/sales-estimator/EstimatorContainer";
import ReduxProvider from "../store/providers/ReduxProvider";
import Ideas from "../components/sales-estimator/Ideas";
import Trends from "../components/sales-estimator/Trends";
import Niche from "../components/sales-estimator/Niche";
import Help from "../components/sales-estimator/Help";
import RatingReviewLine from "../components/common/RatingReviewLine";
import DealsBannerContainer from "../containers/common/DealsBannerContainer";
import DealsSE from "../components/sales-estimator/DealsSE";
import EmptySpace from "../components/sales-estimator/EmptySpace";
import Work from "../components/sales-estimator/Work";

const PgSE = () => {
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  const [getFormBlockState, setFormBlockState] = useState(false);
  const [isShowEmptySpace, setIsShowEmptySpace] = useState(true);

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  const closeYoutubeModal = () => setUrlYoutubeModal("");

  return (
    <ReduxProvider>
      <Layout
        isShowYoutubeModal={isShowYoutubeModal}
        closeYoutubeModal={closeYoutubeModal}
        urlYoutubeModal={urlYoutubeModal}
        // cookieUnderForm={getCookieUnderForm}
        isSimpleTopPanel
        isDisableRecaptcha
        customClass="PgSE"
      >
        <Seo
          title="Free Amazon Sales Estimator Tool | AMZScout"
          description="Our free Amazon Sales Estimator tool shows you detailed sales data for any product. Enter the category and BSR to calculate the monthly sales volume."
          page="sales-estimator"
          manifest="browserconfig.xml"
          ogImg={OgImg}
        />
        {/* <ProPanelContainer*/}
        {/*  getIsSecondFormState={getIsSecondFormState}*/}
        {/*  setIsSecondFormState={setIsSecondFormState}*/}
        {/*  updateCookieUnderForm={setCookieUnderForm}*/}
        {/* />*/}
        <Intro />
        <EstimatorContainer
          getFormBlockState={getFormBlockState}
          setFormBlockState={setFormBlockState}
        />
        <Work />
        <Ideas />
        <Trends />
        <Niche />
        <Help />
        <RatingReviewLine />
        <EmptySpace
          isShow={isShowEmptySpace}
          dealsSection={process.env.DEALS_SE_SECTION}
          isDealsBundle
        />
        <DealsBannerContainer
          dealsUrl={process.env.DEALS_SE_URL}
          dealsSection={process.env.DEALS_SE_SECTION}
          dealsVersion={process.env.DEALS_SE_VERSION}
          label="get_reports"
          category="active"
          setIsShowEmptySpace={setIsShowEmptySpace}
          closureOnlyCross
        >
          <DealsSE />
        </DealsBannerContainer>
      </Layout>
    </ReduxProvider>
  );
};

export default PgSE;
